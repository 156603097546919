// ==============================
// Custom style
// 自定义样式
// ==============================
.page {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  [data-header-desktop] & {
    padding-top: var(--header-height);
  }

  [data-header-desktop=normal] & {
    padding-top: 0;
  }

  @include blur;
}